import Vue from "vue";
const moment = require("moment");
require("moment/locale/ko");

Vue.use(require("vue-moment"), {
  moment,
});

let commMomentMixin = {
  methods: {
    _getCurrYear() {
      return moment().format("YYYY");
    },
    _getCurrMonth() {
      return moment().format("MM");
    },
    _getCurrDay() {
      return moment().format("DD");
    },
    _getMoment(format) {
      return moment().format(format);
    },
    _getCalcMonth(month, format) {
      return moment().add(month, "M").format(format);
    },
    _getCalcYear(year, format) {
      return moment().add(year, "y").format(format);
    },
    _getCalcDay(day, format) {
      return moment().add(day, "d").format(format);
    },
    _getStartDateOfMonth(format) {
      return moment().startOf("month").format(format);
    },
    _getEndDateOfMonth(format) {
      return moment().endOf("month").format(format);
    },
    _getDiff(startDate, endDate, format) {
      startDate = moment(startDate, format);
      endDate = moment(endDate, format);
      return moment.duration(endDate.diff(startDate)).asDays();
    },
    _getBeforeDayYn(date) {
      let today = moment().format("YYYYMM");
      let test = moment(date).isBefore(today); // true
      return test;
    },
  },
};

export default commMomentMixin;
